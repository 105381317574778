export const textEn =
  {
    tit:"SAVE 50% WITH OTM PACK",
    sub:"You can save by purchasing the complete OTM indicator package",
    tit1:"All-in-one OTM Pack",
    p1:"lifetime",
    btn:"Buy now",
};

export const textEs =
  {
    tit:"AHORRA UN 50% CON OTM PACK",
    sub:"Puedes ahorrar comprando el paquete completo de indicadotes OTM",
    tit1:"All-in-one OTM Pack",
    p1:"lifetime",
    btn:"Comprar",
  };
