export const textEn =
  {
    dec:"Risk",
    link1:"OTM",
    link2:"How to use?",
    link3:"Pricing",
    link4:"Results",
    link5:"Contact us",
    link6:"Blog",

  };


export const textEs =
  {
    dec:"Declaraciones",
    link2:"¿Como se usa?",
    link3:"Precios",
    link4:"Resultados",
    link5:"Contactanos",
    link6:"Blog",
  };
