export const textEn =
  {
    tit:"OTM INDICATORS",
    sub:"Use the OTM indicators together and obtain a solid and consistent trading strategy.",
  };


export const textEs =
  {
    tit:"INDICADORES OTM",
    sub:"Utilice en conjunto los indicadores de OTM y obtenga una estrategia de Trading solida y consistente.",
  };
