export const textEn = {
  gen: "Do not close this window until your process is complete!",
  tit: "Thank you for purchasing",
  tit2: "OTM Software",
  sub: "You are only a few steps away from having your OTM software",
  one: "1. Get the ID machine of your Ninjatrader 8:",
  oneSub:
    "The Machine ID code is generated by NinjaTrader based on the main hardware and software system.",
  two: "2. Send us the machine ID and your data in the following form:",
  name: "Name",
  nameE: "Jhon doe",
  id: "ID machine",
  idE: "AAAAAAAAAAAAAAAA",
  email: "Email",
  emailE: "Jhon@mail.com",
  message: "Message",
  messageE: "...",
  btn: "Submit",
  text1: "You will receive your software in your email within 1 business day (8 to 5 PM GMT-4).",
  text2: "If you have any questions please do not hesitate to contact us:",
  text3: "info@otmtrading.com",
  text4: "WhatsApp support (+57 311 3006826)",
  fail:"The message could not be sent, if the problem persists contact info@otmtrading.com",
    succes:"The message was sent successfully. We will contact you soon.",


};

export const textEs = {
  gen: "¡No cierres esta ventana hasta completar tu proceso!",
  tit: "Gracias por adquirir",
  tit2: "OTM Software",
  sub: "Sigue atentamente estos pasos para tener tu OTM",
  one: "1. Obtén el Machine ID de tu NinjaTrader 8:",
  oneSub:
    "El código Machine ID es generado por NinjaTrader basado en el hardware y software principal de sistema.",
  two: "2. Después de enviar este formulario, crearemos la licencia para tu equipo.",
  name: "Name",
  nameE: "Jhon doe",
  id: "ID machine",
  idE: "AAAAAAAAAAAAAAAA",
  email: "Email",
  emailE: "Jhon@mail.com",
  message: "Message",
  messageE: "...",
  btn: "Enviar",
  text1: "Recibirás en tu correo tu software en un plazo de 1 día hábil (8 a 5 PM GMT- 4).",
  text2: "Si tienes cualquier inquietud no dudes en contactarnos:",
  text3: "info@otmtrading.com",
  text4: "WhatsApp de soporte (+57 311 3006826)",
  fail:"El mensaje no pudo ser enviado, si el problema persiste comunicarse con info@otmtrading.com",
  succes:"El mensaje fue enviado exitosamente. Pronto nos contactaremos contigo.",

};
