export const textEn =
  {
    tit1: "Analysis Software For Volume and Delta",
    subt: "Easily analyze the buying and selling strength of the futures market.",
    buttMore: "Read more"
  };

export const textEs =
  {
    tit1: "Software para el analisis de volumen y delta",
    subt: "Analice fácilmente la fuerza compradora y vendedora del mercado de futuros.",
    buttMore: "Leer más"

  };
