export const textEn =
  {
    tit:"Trade simple and smart using OTM indicators!",
    sub:"Are you ready to take your trading to the next level?",
    btn:"Buy now",
  };


export const textEs =
  {
    tit:"¡Haga trading de forma simple e inteligente usando los indicadores de OTM!",
    sub:"¿Estás listo para llevar tu trading al siguiente nivel?",
    btn:"Comprar ahora",
  };
