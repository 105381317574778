export const textEn =
  {
    tit:"Want a particular indicator?",
    sub:"You can get any OTM indicator individually",
    btn:"Buy now",
};

export const textEs =
  {
    tit:"¿Quieres un indicador en particular?",
    sub:"Puedes adquirir cualquier indicador OTM por separado",
    btn:"Comprar",
  };
