export const ReviewsDataEn = [
  {
    imgUrl: "./review/rev_fedver.jpeg",
    title: "Federico Verdejo",
    video: "./rev_federico_verd.mp4",
  },
  {
    imgUrl: "./review/rev_gusloi.jpeg",
    title: "Gustavo loiz",
    video: "./rev_gustavo_loiz.mp4",
  },
  {
    imgUrl: "./review/rev_wilfra.jpeg",
    title: "William franco",
    video: "./rev_william_franc.mp4",
  }
];


export const ReviewsDataEs = [
  {
    imgUrl: "./review/rev_fedver.jpeg",
    title: "Federico Verdejo",
    video: "./rev_federico_verd.mp4",
  },
  {
    imgUrl: "./review/rev_gusloi.jpeg",
    title: "Gustavo loiz",
    video: "./rev_gustavo_loiz.mp4",
  },
  {
    imgUrl: "./review/rev_wilfra.jpeg",
    title: "William franco",
    video: "./rev_william_franc.mp4",
  }

];